import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react'
import { useWeb3Modal } from '@web3modal/ethers/react'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { BrowserProvider, Contract, Eip1193Provider, formatUnits,  } from 'ethers'
import { useEffect, useState } from 'react'
import day from './images/DAY.png'
import noon from './images/NOON.png'
import night from './images/NIGHT.png'
import logo from './images/logo.png'
import bananawithmoney from './images/Banana_with_money.png'
import bananacat from './images/Cat_With_Money.png'
import plusimg from './images/plusbtn.png'
import negativeimg from './images/negativebtn.png'
import season1img from './images/season1.png'
import season2img from './images/season2.png'
import season3img from './images/season3.png'
import ticketimg from './images/Ticket.png'
import catcrying from './images/catcrying.gif'
import bananadancing from './images/bananadancing.gif'
import Swal from 'sweetalert2'
import Countdown from 'react-countdown';

import './App.css'
const projectId = '4cc02e4d9d260ac39b16976d027505e0'
const mainnet = {
  chainId: 8453,
  name: 'Base Mainnet',
  currency: 'DEGEN',
  explorerUrl: 'https://base.blockscout.com/',
  rpcUrl: 'https://mainnet.base.org',
};

const metadata = {
  name: 'Banana Bonanza',
  description: 'Are you ready to win?',
  url: 'http://bananabonanza.lol',
  icons: ['https://bananabonanza.lol/img/logo.png']
}


const ethersConfig = defaultConfig({
  metadata,
})

createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true, 
  enableOnramp: true 
})

export default function App() {
  const [contract ,setContract] = useState(null)
  const [theme, setTheme] = useState("day")
  const [popup, setPopup] = useState(false)
  const [background, setBackground] = useState(day)
  const [bananabackground, setBananabackground] = useState(bananacat)
  const [banaSize, setbanaSize] = useState([80, 80])
  
  // notconnected rounddetails history playerlist winner losser
  const [screen, setScreen] = useState("notconnected")
  const [ticketAmonth, setTicketAmonth] = useState(1)
  const [chances, setChances] = useState(0)

  const [rounddetail, setrounddetail] = useState({
    roundId: 0,
    ticketPrice: 0,
    totalCollected: 0,
    totalPlayers: 0,
    totalChances: 0,
    isActive: "No"
  }) 
  const { open } = useWeb3Modal()
  
  const contractAddress = '0x20719A642A12164C21AE29F6EE83A327666eC89d';
  const lotteryAbi = [
    'function buyTickets(uint256 numTickets) payable',
    'function givePrizes()',
    'function startRound(uint256 _ticketPrice)',
    'function currentRoundId() view returns (uint256)',
    'function getPlayerDetails(uint256 roundId, address playerAddress) view returns (uint256 chances, uint256 index)',
    'function getRoundDetails(uint256 roundId) view returns (uint256, uint256, uint256, uint256, bool)',
    'function getWinnerCount(uint256 roundId) view returns (uint256)',
    'function getWinnerDetail(uint256 roundId, uint256 winnerIndex) view returns (address, uint256)',
    'function isAnyRoundActive() view returns (bool)',
    'function owner() view returns (address)',
    'function roundPlayers(uint256, uint256) view returns (address addr, uint256 chances)',
    'function rounds(uint256) view returns (uint256 ticketPrice, uint256 totalCollected, uint256 totalPlayers, uint256 totalChances, bool isActive)'
  ];
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()

  async function setupLotteryContract() {
    if (!isConnected) throw Error('User disconnected')
  
    // console.log(walletProvider)
    const ethersProvider = new BrowserProvider(walletProvider as Eip1193Provider)
    const signer = await ethersProvider.getSigner()
    const contract = new Contract(contractAddress, lotteryAbi, signer)
    return contract
  }

  const now = new Date();
  now.setUTCHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to 0 in UTC timezone
  const nextTimesunday = now.getTime() + (8 - now.getUTCDay()) * 24 * 60 * 60 * 1000;
 
  async function buyTickets(num : number = 1 ) {
    if (!isConnected) throw Error('User disconnected')
    const lotteryContract = await setupLotteryContract()
    const ticketPrice = await lotteryContract.rounds(await lotteryContract.currentRoundId())
    const biginttickets = BigInt(num)
    const totalCost = ticketPrice.ticketPrice * biginttickets;

    try {
      Swal.fire({
        title: 'Please Check Your Wallet !',
        allowOutsideClick: false,
        color: "var(--color-color4)",
        background: "var(--color-color1)",
      });
      Swal.showLoading();
      const result = await lotteryContract.buyTickets(num, { value: totalCost })
      // console.log(result)
      Swal.fire({
        title: 'Your Payment Was Successful',
        icon: "success",
        allowOutsideClick: false,
        color: "var(--color-color4)",
        background: "var(--color-color1)",
      });
      getPlayerDetails()
      getRoundDetails()
      // buyed or not buyed 
    } catch (error: any) {
      // console.log(error)
      if (String(error).toLowerCase().includes("no active round")) {
        Swal.fire({
          title: "no Active Round At the Moment :(",
          width: 600,
          padding: "1em",
          color: "var(--color-color4)",
          background: "var(--color-color1)",

        });
      } else { 
        Swal.fire({
          title: "Buying Process Failed :(",
          icon: "error",
          width: 600,
          padding: "1em",
          color: "var(--color-color4)",
          background: "var(--color-color1)",

        });
      }
    }

  }

  async function getRoundDetails(): Promise<string> {
    if (!isConnected) throw Error('User disconnected');
    const lotteryContract = await setupLotteryContract()
    const id = await getRoundId()
    const round = await lotteryContract.getRoundDetails(id);
    setrounddetail({
      roundId: id,
      ticketPrice: Number(round[0] / BigInt(10 ** 18)),
      totalCollected: Number(round[1] / BigInt(10 ** 18)),
      totalPlayers: Number(round[2]),
      totalChances: Number(round[3]),
      isActive: round[4] ? 'Yes' : 'No'
    })
        const details = `
        Round ID: ${id}
        Ticket Price: ${round[0] / BigInt(10 ** 18)} Degens
        Total Collected: ${round[1] / BigInt(10 ** 18)} Degens
        Total Players: ${round[2]}
        Total Chances: ${round[3]}
        Is Active: ${round[4] ? 'Yes' : 'No'}
    `;

        // console.log(details)
        return details;
  }


  async function getRoundId() { 
    if (!isConnected) throw Error('User disconnected');
    const lotteryContract = await setupLotteryContract()
    const roundId = await lotteryContract.currentRoundId();
    const Id = Number(roundId)
    return Id
  }

  async function getPlayerDetails() {
    if (!isConnected) throw Error('User disconnected');
    try {
      const lotteryContract = await setupLotteryContract()
      const details = await lotteryContract.getPlayerDetails(rounddetail.roundId, address);
      const playerchance = details?.chances
      setChances(Number(details[0]))
    } catch (error) {
      setChances(0)
      // console.log(error)
    }
  
  }


  async function fetchWinnersDetails(roundId : Number = 2) {
    if (!isConnected) throw Error('User disconnected');
    const lotteryContract = await setupLotteryContract()
    const winnersDetails = [];
   
      for (let winnerIndex = 0; winnerIndex < 10; winnerIndex++) {
        const winnerDetail = await lotteryContract.getWinnerDetail(roundId, winnerIndex);
        winnersDetails.push({
          address: winnerDetail[0],
          prizeAmount: formatUnits(winnerDetail[1])
        });
      }
    // console.log(winnersDetails)
    return winnersDetails;
  }


  function changeTheme() {
    // console.log(theme)
    if (theme === "day") {
      setTheme("noon")
      setBackground(noon)
    } else
      if (theme === "noon") {
        setTheme( "night")
        setBackground(night)
      } else
        if (theme === "night") {
          setTheme("day")
          setBackground(day)
        }
  }

  function changeScreen() {

    if (screen === "rounddetails" || screen === "loser" || screen === "winner") {
      if (rounddetail.roundId === 0) {
        return
      }
      getLastThreeSeasons()
      setScreen("history")
      // // go to history

      // currentScreen = "history"
    } else

      if (screen === "playerlist") {
        // go to history
        setScreen("history")

        // currentScreen = "history"
        // getLastThreeSeasons()
      } else

        if (screen === "history") {
          //   // go to rounddetails
          //   document.getElementById("backicon").style.display = "none"
          setScreen("rounddetails")
          //   currentScreen = "rounddetail"
        }
  }

  function checkTime() {
    const date = new Date();
    const hours = date.getHours();
    if (hours >= 12 && hours < 18) {
      setTheme("noon")
      setBackground(noon)
    } else if ((hours >= 18 && hours <= 23) || (hours >= 0 && hours < 6)) {
      setTheme("night")
      setBackground(night)
    } else {
      setTheme("day")
      setBackground(day)
    }
  }
  const renderer = ({ days = 0, hours = 0, minutes = 0, seconds = 0, completed = false }) => {
    if (completed) {
      winnerorLoser()
    } else {
      // Render a countdown
      return (<g xmlns="http://www.w3.org/2000/svg" filter="url(#filter12_d_52_392)">
        <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
          fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
          <tspan x="409" y="673" id="daysText2">{days}D</tspan>
        </text>
        <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
          fontFamily="Press Start 2P" fontSize="24" letterSpacing="0em">
          <tspan x="553" y="673"></tspan>
        </text>
        <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
          fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
          <tspan x="601" y="673" id="countdownTimer2">{hours}:{minutes}:{seconds}</tspan>
        </text>
      </g>)
    }
  };

  const renderer1 = ({ days = 0, hours = 0, minutes = 0, seconds = 0, completed = false }) => {
    if (completed) {
      winnerorLoser()
    } else {
      // Render a countdown
      return (
        <g filter="url(#filter6_d_9_3795)" id="countertime1" className={` ${screen === "notconnected" ? "" : "hidden"} `}>
          <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
            fontFamily="Press Start 2P" fontSize="24" letterSpacing="0em">
            <tspan x="379" y="255"> </tspan>
          </text>
          <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
            fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
            <tspan x="331" y="255"></tspan>
            <tspan id="countdownTimer" x="427" y="255">{hours}:{minutes}:{seconds}</tspan>
          </text>
          <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
            fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
            <tspan x="235" y="255" id="daysText">{days}D</tspan>
          </text>
        </g>)
    }
  };

  async function winnerorLoser() {
      const winners = await fetchWinnersDetails(rounddetail.roundId);
      let isWinner = false;

      winners.forEach(function (winner) {
        if (winner.address.toLowerCase() === address?.toLowerCase()) {
          isWinner = true;
        }
      });

      if (isWinner) {
        // The user is a winner
        setScreen("winner");
      } else {
        // The user is not a winner
        setScreen("loser");
      }
    
  }


  const [loading , setLoading]  = useState(false) 
  const [winners1, setWinners1] = useState<{ address: any; prizeAmount: string }[]>([]);
  const [winners2, setWinners2] = useState<{ address: any; prizeAmount: string }[]>([]);
  const [winners3, setWinners3] = useState<{ address: any; prizeAmount: string }[]>([]);
  const [currentwinners, setCurrentWinners ] = useState<{ address: any; prizeAmount: string }[]>([]);
  const [round1Id, setround1Id] = useState(-1) 
  const [round2Id, setround2Id] = useState(-1) 
  const [round3Id, setround3Id] = useState(-1) 

  const ShowWinners = (id:number) => { 
    setScreen("playerlist")
    if (id === 1) { setCurrentWinners(winners1) }
    if (id === 2) { setCurrentWinners(winners2) }
    if (id === 3) { setCurrentWinners(winners3) }
  }
  async function getLastThreeSeasons() {
    setLoading(true)
    // 3 
    // 1 - 1  = 0
    // 1 - 2  = -1
    // 3 - 3  = -2 
    let season1Id = Number(rounddetail.roundId) - 1;
    let season2Id = Number(rounddetail.roundId) - 2;
    let season3Id = Number(rounddetail.roundId) - 3;

    if (season3Id >= 1) {
      const gamewinners3 = await fetchWinnersDetails(season3Id)
      setWinners3(gamewinners3)
      if (gamewinners3) {
        setround3Id(season3Id)
      }
    }
    if (season2Id >= 1) {
      const gamewinners2 = await fetchWinnersDetails(season2Id)
      setWinners2(gamewinners2)
      if (gamewinners2) {
        setround2Id(season2Id)
      }
    }
    if (season1Id >= 1) {
      const gamewinners1 = await fetchWinnersDetails(season1Id)
      setWinners1(gamewinners1)
      if (gamewinners1) {
        setround1Id(season1Id)
      }
    }


    // console.log(gamewinners1)
   setLoading(false)
  }

  useEffect(() => {
    document.querySelector("html")?.setAttribute("data-theme", theme);
   },[theme])
  
  useEffect(() => {
    checkTime()
  }, [])
  useEffect(() => {
    if (rounddetail.roundId) { 
      getPlayerDetails()
    }
  }, [rounddetail])

  useEffect(() => {
    if (isConnected) {
      setScreen("rounddetails")
      setbanaSize([80, 80])
      setBananabackground(bananawithmoney)
      getRoundDetails()
      getPlayerDetails()
    } else {
      setScreen("notconnected")
      setBananabackground(bananacat)
      setbanaSize([80, 100])
    }
  }, [isConnected])

  useEffect(() => {
    if (address) {
      getPlayerDetails()
      getRoundDetails()
     // console.log(address ,"address changed")
    }
  }, [address])



  return (
    <div>
      {/* <button onClick={() => open()}>Open Connect Modal</button>
      <button onClick={() => open({ view: 'Networks' })}>Open Network Modal</button>
      <button onClick={() => buyTickets()}>buybtn</button>
      <button onClick={() => getRoundDetails()}>rounddetails</button>
      <button onClick={() => getRoundId()}>roundId</button>
      <button onClick={() => fetchWinnersDetails()}>fetchWinnersDetails</button> */}
      {/* <button onClick={() => changeTheme()}>change theme</button> */}
      {/* btns */}
      <button className="fixed bg-color1 text-xs text-color4 p-2  top-2 right-1 rounded-md shadow-color4 shadow z-30 "
        onClick={() => open()} id="walletAddress1">{address ? address.slice(0,4)+'..'+address.slice(-4) : "connect wallet"}</button>
      {/* <button className="fixed bg-color1 text-xs text-color4 p-2  top-2 right-1 rounded-md shadow-color4 shadow z-30 "
        onClick={() => setScreen('winner')} id="walletAddress1">winner</button>
      <button className="fixed bg-color1 text-xs text-color4 p-2  top-2 right-1 rounded-md shadow-color4 shadow z-30 "
        onClick={() => setScreen('loser')} id="walletAddress1">loser</button> */}
        {/* <button className="fixed bg-color1 text-xs text-color4 p-2  top-2 right-1 rounded-md shadow-color4 shadow z-30 "
          onClick={() => getPlayerDetails()} id="walletAddress1">get player details</button>  */}
      {/* about section */}
      {popup && 
      <section  className="w-screen h-screen md:p-10 p-2 bg-transparent fixed z-30 bg-[#00000090] ">
        <div className="w-full h-full  relative bg-color1   border-8 border-color7  md:p-16 p-8 ">
          <svg className=" w-10 h-10 z-50 " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 82 82" fill="none"
              onClick={() => setPopup(false)}>
            <g filter="url(#filter0_dii_189_34)">
              <rect x="6" y="6" width="71.2704" height="71.2704" rx="10" className="fill-color8" />
              <path
                d="M17.5 60V48H23.5V42H29.5V36H23.5V30H17.5V18H29.5V30H35.5V36H41.5V30H47.5V18H59.5V30H53.5V36H47.5V42H53.5V48H59.5V60H47.5V48H41.5V42H35.5V48H29.5V60H17.5Z"
                className="fill-color7" />
            </g>
            <defs>
              <filter id="filter0_dii_189_34" x="0" y="0" width="82.2695" height="82.2705"
                filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dx="-1" dy="-1" />
                <feGaussianBlur stdDeviation="2.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_189_34" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_189_34" result="shape" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dx="5" dy="5" />
                <feGaussianBlur stdDeviation="5" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_189_34" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dx="-2" dy="-2" />
                <feGaussianBlur stdDeviation="2.5" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
                <feBlend mode="normal" in2="effect2_innerShadow_189_34" result="effect3_innerShadow_189_34" />
              </filter>
            </defs>
          </svg>
          <div
            className="md:w-5/6 w-full h-full overflow-y-scroll md:overflow-y-hidden overflow-x-hidden pb-10 md:pb-0  text-color4 ">

            <h1 style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="py-2 md:text-xl text-lg">Welcome to
              BananaBonanza!</h1>
            <p style={{textShadow:"3px 4px 4px var(--color-color1)"}} className='pb-4 md:text-sm text-xs'>
              Step Right Up to the DEGEN Chain Lottery Wonderland!Hey there, Lucky!Feeling adventurous? You've
              just found
              the most
              exciting spot on the DEGEN chain!
              BananaBonanza is't just a lottery, it's your new favorite place to play, win, and celebrate.
            </p>
            <h2 style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="py-2 md:text-lg text-sm">
              Why You're Going to Love It Here:
            </h2>
            <h3 style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="py-2 text-sm">
              Secure Your Fun:
            </h3>
            <p style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="text-xs">
              With top-notch blockchain technology, your bets are as secure as a vault! Play with peace of mind
              knowing we've got your back.
            </p>

            <h3 style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="py-2 text-sm">
              Fairness in Every Draw:
            </h3>
            <p style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="text-xs">
              Our draws are as random as finding a four-leaf clover in the wild—ensuring everyone has an equal
              chance to hit the jackpot.
            </p>
            <h3 style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="py-2 text-sm">
              A Whole Lot More than Winning:
            </h3>
            <p style={{textShadow:"3px 4px 4px var(--color-color1)"}} className="text-xs">
              BananaBonanza is about joy, laughter, and the thrill of the game. Win or lose, you’re
              guaranteed to have a blast.
              So, what are you waiting for? Grab your $DEGEN tokens, pick your numbers, and let’s get those good
              vibes
              rolling! It's
              time to win big, laugh loud, and maybe, just maybe, go a little bananas. Come on over to
              BananaBonanza—where
              every play
              is a reason to smile!
            </p>
          </div>

            <img className="absolute  -bottom-10 -right-16  md:-bottom-20 md:-right-20 md:w-72 md:h-72 h-36 w-36 z-50 " src={logo}
            alt="banana" />
          <img className="absolute top-2 right-10 w-24 h-24 z-50 animate-pulse " src={bananawithmoney}
            alt="banana" />


        </div>
      </section>
      }
      {/* <!-- background --> */}
      <img src={background} className="flex object-cover w-screen h-screen absolute -z-10" draggable="false" />
      <div className="flex justify-center">
        <div
          className="flex w-screen h-screen  items-center justify-between gap-4 md:flex-row flex-col-reverse max-w-screen-xl md:pl-4 ">
          {/* <!-- left pannel --> */}
          <main className=" w-full md:w-8/12 h-full    ">
            <div className=" relative  w-full  h-[300px] md:h-full  flex flex-col items-start">
              {/* <!-- className=" w-full top-0 left-0 -z-10 h-full " --> */}
              <svg className=" w-full h-full " xmlns="http://www.w3.org/2000/svg" onClick={()=>console.log("")}
                xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1046 750" fill="none">
                <g clipPath="url(#clip0_9_3795)">
                  <rect className="fill-color1" x="17" y="63" width="1012" height="665" rx="36" />
                  {/* <!--  change theme  btn --> */}
                  <g filter="url(#filter2_ii_9_3795)" onClick={()=>changeTheme()}>
                    <rect x="914" y="95" width="71.2704" height="71.2704" rx="10" className="fill-color7" />

                    {/* <!-- sun --> */}
                    { theme === 'noon' && 
                    <path xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"
                      id="sunicon"
                      d="M971.273 126.273V128.636H968.909V133.364H971.273V135.727H973.636V138.091H976V142.818H968.909V145.182H964.182V149.909H961.818V157H957.091V154.636H954.727V152.273H952.364V149.909H947.636V152.273H945.273V154.636H942.909V157H938.182V149.909H935.818V145.182H931.091V142.818H924V138.091H926.364V135.727H928.727V133.364H931.091V128.636H928.727V126.273H926.364V123.909H924V119.182H931.091V116.818H935.818V112.091H938.182V105H942.909V107.364H945.273V109.727H947.636V112.091H952.364V109.727H954.727V107.364H957.091V105H961.818V112.091H964.182V116.818H968.909V119.182H976V123.909H973.636V126.273H971.273ZM961.818 138.091H964.182V123.909H961.818V121.545H959.455V119.182H957.091V116.818H942.909V119.182H940.545V121.545H938.182V123.909H935.818V138.091H938.182V140.455H940.545V142.818H942.909V145.182H957.091V142.818H959.455V140.455H961.818V138.091ZM961.818 135.727V126.273H959.454V123.909H957.091V121.545H954.727V119.182H945.273V121.545H942.909V123.909H940.545V126.273H938.182V135.727H940.545V138.091H942.909V140.454H945.273V142.818H954.727V140.454H957.091V138.091H959.454V135.727H961.818Z"
                      className="fill-color8" />
                    }
                   
                    {/* <!-- moon --> */}
                    {theme == 'night' &&
                      <path xmlns="http://www.w3.org/2000/svg" id="moonicon"
                        d="M976 144.182V149.455H973.4V152.091H970.8V154.727H965.6V157.364H960.4V160H944.8V157.364H939.6V154.727H934.4V152.091H931.8V149.455H929.2V144.182H926.6V138.909H924V123.091H926.6V117.818H929.2V112.545H931.8V109.909H934.4V107.273H939.6V104.636H944.8V102H957.8V104.636H952.6V107.273H947.4V109.909H944.8V115.182H942.2V120.455H939.6V131H942.2V136.273H944.8V141.545H947.4V144.182H952.6V146.818H957.8V149.455H968.2V146.818H973.4V144.182H976Z"
                        className="fill-color8" />
                    }
                    {/* <!-- day --> */}
                    {theme == 'day' &&
                     <path id="dayicon"
                      d="M972 121.5V139.5H967.5V144H963V148.5H958.5V153H940.5V148.5H936V144H931.5V139.5H927V121.5H931.5V117H936V112.5H940.5V108H958.5V112.5H963V117H967.5V121.5H972Z"
                      className="fill-color8" />
                    }
                   


                  </g>

                  {/* display popup */}
                    <g filter="url(#filter3_ii_9_3795)" onClick={() => setPopup(true)}>
                      <rect x="58" y="95" width="71.2704" height="71.2704" rx="10" className="fill-color7" onClick={() => setPopup(true)} />
                    <text className="fill-color8" xmlSpace="preserve" style={{ whiteSpace: "pre" }} onClick={() => setPopup(true)}
                      fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
                      <tspan x="69.5" y="154.5">i</tspan>
                    </text>
                  </g>
                  

                  <line x1="55" y1="433.001" x2="55" y2="239.999" className="stroke-color5" strokeWidth="5"
                    strokeDasharray="200 45 30 80" />
                  <line x1="720" y1="698.5" x2="985.008" y2="698.5" className="stroke-color5" strokeWidth="5"
                    strokeDasharray="100 10 30 20" />
                  <g filter="url(#filter4_ii_9_3795)" className={screen === "notconnected" ? "hidden" : "" } id="historybtn" onClick={()=>changeScreen()}>
                    <rect x="915" y="201" width="71.2704" height="71.2704" rx="10" className="fill-color7" />
                    {/* histroy icon */}
                    <g id="historyicon" className={screen === "rounddetails" || screen === "loser" || screen === "winner" ? "" : "hidden"}>
                      <path d="M961.667 258.5H929.167V260.667H961.667V258.5Z" fill="black" />
                      <path d="M929.167 215.167H927V258.5H929.167V215.167Z" fill="black" />
                      <path d="M961.667 213H929.167V215.167H961.667V213Z" fill="black" />
                      <path d="M972.5 230.333H970.333V232.5H972.5V230.333Z" fill="black" />
                      <path d="M970.333 232.5H968.167V234.667H970.333V232.5Z" fill="black" />
                      <path d="M972.5 223.833H968.167V226H972.5V223.833Z" fill="black" />
                      <path d="M974.667 226H972.5V230.333H974.667V226Z" fill="black" />
                      <path d="M968.167 234.667H966V236.834H968.167V234.667Z" fill="black" />
                      <path d="M968.167 232.5H966V234.667H968.167V232.5Z" fill="#BC703C" />
                      <path d="M968.167 230.333H966V232.5H968.167V230.333Z" fill="black" />
                      <path
                        d="M972.5 226V230.333H970.333V232.5H968.167V230.333H966V228.167H968.167V226H972.5Z"
                        fill="#C3332F" />
                      <path d="M968.167 226H966V228.167H968.167V226Z" fill="black" />
                      <path d="M966 236.833H963.833V239H966V236.833Z" fill="black" />
                      <path d="M966 234.667H963.833V236.834H966V234.667Z" fill="#BC703C" />
                      <path d="M966 228.167V230.334H963.833V232.5H961.667V215.167H963.833V228.167H966Z"
                        fill="black" />
                      <path d="M963.833 239V258.5H961.667V243.333H959.5V241.167H961.667V239H963.833Z"
                        fill="black" />
                      <path d="M963.833 236.833H961.667V239H963.833V236.833Z" fill="#BC703C" />
                      <path d="M961.667 239H959.5V241.167H961.667V239Z" fill="#BC703C" />
                      <path d="M961.667 232.5H959.5V234.667H961.667V232.5Z" fill="black" />
                      <path d="M959.5 243.333H957.333V245.5H959.5V243.333Z" fill="black" />
                      <path d="M959.5 241.167H957.333V243.334H959.5V241.167Z" fill="#BC703C" />
                      <path d="M959.5 234.667H957.333V236.834H959.5V234.667Z" fill="black" />
                      <path d="M957.333 245.5H955.167V247.667H957.333V245.5Z" fill="black" />
                      <path d="M957.333 243.333H955.167V245.5H957.333V243.333Z" fill="#BC703C" />
                      <path d="M957.333 236.833H955.167V239H957.333V236.833Z" fill="black" />
                      <path
                        d="M931.333 217.333V256.333H944.333V254.167H946.5V252H948.667V243.333H950.833V241.167H953V239H955.167V236.833H957.333V234.667H959.5V217.333H931.333ZM942.167 223.833H944.333V221.667H946.5V223.833H948.667V226H946.5V228.167H944.333V226H942.167V223.833ZM933.5 223.833H935.667V221.667H937.833V223.833H940V226H937.833V228.167H935.667V226H933.5V223.833ZM944.333 247.667H933.5V245.5H944.333V247.667ZM946.5 241.167H933.5V239H946.5V241.167ZM953 234.667H933.5V232.5H953V234.667ZM957.333 226H955.167V228.167H953V226H950.833V223.833H953V221.667H955.167V223.833H957.333V226Z"
                        fill="#F8F8F8" />
                      <path d="M955.167 247.667V249.833H948.667V243.333H950.833V247.667H955.167Z"
                        fill="black" />
                      <path d="M955.167 245.5H953V247.667H955.167V245.5Z" fill="#BC703C" />
                      <path d="M955.167 239H953V241.167H955.167V239Z" fill="black" />
                      <path
                        d="M957.333 223.834V226H955.167V228.167H953V226H950.833V223.834H953V221.667H955.167V223.834H957.333Z"
                        fill="#FFD100" />
                      <path
                        d="M966 230.333V234.667H963.833V236.833H961.667V239H959.5V241.167H957.333V243.333H955.167V245.5H953V247.667H950.833V243.333H953V241.167H955.167V239H957.333V236.833H959.5V234.667H961.667V232.5H963.833V230.333H966Z"
                        fill="#DBC693" />
                      <path d="M953 241.167H950.833V243.334H953V241.167Z" fill="black" />
                      <path d="M959.5 249.833V256.333H944.333V254.167H946.5V252H957.333V249.833H959.5Z"
                        fill="#DAD9D9" />
                      <path
                        d="M948.667 223.834V226H946.5V228.167H944.333V226H942.167V223.834H944.333V221.667H946.5V223.834H948.667Z"
                        fill="#FFD100" />
                      <path d="M944.333 245.5H933.5V247.667H944.333V245.5Z" fill="#A6A6A6" />
                      <path
                        d="M940 223.834V226H937.833V228.167H935.667V226H933.5V223.834H935.667V221.667H937.833V223.834H940Z"
                        fill="#FFD100" />
                      <path d="M946.5 239H933.5V241.167H946.5V239Z" fill="#A6A6A6" />
                      <path d="M953 232.5H933.5V234.667H953V232.5Z" fill="#A6A6A6" />
                      <path
                        d="M959.5 232.5V217.334H931.333V256.334H959.5V249.834H957.333V252H948.667V249.834H955.167V247.667H957.333V245.5H959.5V243.334H961.667V258.5H929.167V215.167H961.667V232.5H959.5Z"
                        fill="#A6A6A6" />
                    </g>
                    {/* back icon */}
                    <path className={screen === "history" || screen === "playerlist"  ? "" : "hidden"} id="backicon" xmlns="http://www.w3.org/2000/svg"
                      fillRule="evenodd" clipRule="evenodd"
                      d="M929 226.286V229.143H930.429V230.571H931.857V232H933.286V233.429H934.714V234.857H936.143V236.286H937.571V237.714H939V239.143H940.429V240.571H941.857V242H943.286V243.429H944.714V242H946.143V240.571H947.571V239.143H946.143V237.714H944.714V236.286H943.286V234.857H941.857V233.429H940.429V232H939V230.571H960.429V232.286H963.286V234.143H964.714V237.857H966.143V249H964.714V252.714H963.286V254.571H960.429V256.429H939V262H960.429V260.143H963.286V258.286H966.143V256.429H967.571V252.714H969V249H970.429V237.857H969V234.143H967.571V230.429H966.143V228.571H963.286V226.714H960.429V224.857H939V223.429H940.429V222H941.857V220.571H943.286V219.143H944.714V217.714H946.143V216.286H947.571V214.857H946.143V213.429H944.714V212H943.286V213.429H941.857V214.857H940.429V216.286H939V217.714H937.571V219.143H936.143V220.571H934.714V222H933.286V223.429H931.857V224.857H930.429V226.286H929Z"
                      fill="#281E2F" />
                  </g>

                  <g filter="url(#filter5_d_9_3795)" className={` ${screen === "notconnected" ? "" : "hidden"} `}>
                    <text id="outofreach" className="fill-color4 animate-pulse" xmlSpace="preserve"
                      style={{whiteSpace:"pre"}} fontFamily="Press Start 2P" fontSize="30"
                      letterSpacing="0em">
                      <tspan x="298" y="339">OUT OF REACH!!!
                      </tspan>
                      <tspan x="523" y="369">
                      </tspan>
                      <tspan x="178" y="399">Pls connect your WALLET</tspan>
                    </text>
                  </g>
                  <g id="players" className={ ` ${ screen === "playerlist" ? "" : "hidden"}`}>

                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter11_d_52_392)">
                      <text className="fill-color3" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan x="400" y="173">Winners:</tspan>
                      </text>
                    </g>

                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="239" className="walletaddress fill-color4">{ currentwinners[0]?.address.slice(0, 8) + "..." + currentwinners[0]?.address.slice(-5) + " " +currentwinners[0]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="273" className="walletaddress fill-color4">{ currentwinners[1]?.address.slice(0, 8) + "..." + currentwinners[1]?.address.slice(-5) + " " +currentwinners[1]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="307" className="walletaddress fill-color4">{ currentwinners[2]?.address.slice(0, 8) + "..." + currentwinners[2]?.address.slice(-5) + " " +currentwinners[2]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="341" className="walletaddress fill-color4">{ currentwinners[3]?.address.slice(0, 8) + "..." + currentwinners[3]?.address.slice(-5) + " " +currentwinners[3]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="375" className="walletaddress fill-color4">{ currentwinners[4]?.address.slice(0, 8) + "..." + currentwinners[4]?.address.slice(-5) + " " +currentwinners[4]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="409" className="walletaddress fill-color4">{ currentwinners[5]?.address.slice(0, 8) + "..." + currentwinners[5]?.address.slice(-5) + " " +currentwinners[5]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="443" className="walletaddress fill-color4">{ currentwinners[6]?.address.slice(0, 8) + "..." + currentwinners[6]?.address.slice(-5) + " " +currentwinners[6]?.prizeAmount }</tspan>
                      </a>
                    </text>

                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="477" className="walletaddress fill-color4">{ currentwinners[7]?.address.slice(0, 8) + "..." + currentwinners[7]?.address.slice(-5) + " " +currentwinners[7]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="511" className="walletaddress fill-color4">{ currentwinners[8]?.address.slice(0, 8) + "..." + currentwinners[8]?.address.slice(-5) + " " +currentwinners[8]?.prizeAmount }</tspan>
                      </a>
                    </text>


                    <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="22" letterSpacing="0em">
                      <a
                        href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075?tab=internal_txns">
                        <tspan x="230" y="545" className="walletaddress fill-color4">{ currentwinners[9]?.address.slice(0, 8) + "..." + currentwinners[9]?.address.slice(-5) + " " +currentwinners[9]?.prizeAmount }</tspan>
                      </a>
                    </text>

                  </g>

                  {/* <!-- round detail --> */}
                  <g id="rounddetail" className={screen === "rounddetails" || screen === "winner" || screen === "loser" ? "" : "hidden"}>
                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter11_d_52_392)">
                      <text className="fill-color3" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan x="248" y="173">One ticket = one degen</tspan>
                      </text>
                    </g>

                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter2_d_52_392)">
                      <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan id="roundId" x="139" y="239">Week Number: {rounddetail.roundId}</tspan>
                      </text>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter3_d_52_392)">
                      <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan id="ticketPrice" x="139" y="298">Ticket Price: {rounddetail.ticketPrice} Degens</tspan>
                      </text>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter4_d_52_392)">
                      <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan id="totalCollected" x="139" y="357">Total Collected:{rounddetail.totalCollected} Degens</tspan>
                      </text>
                    </g>
                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter5_d_52_392)">
                      <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan id="totalPlayers" x="139" y="416">Total Players: {rounddetail.totalPlayers}</tspan>
                      </text>
                    </g>

                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter6_d_52_392)">
                      <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan id="totalChance" x="139" y="475">Total Chances: {rounddetail.totalChances}</tspan>
                      </text>
                    </g>

                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter7_d_52_392)">
                      <text className="fill-color4" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan id="isActive" x="139" y="534">Is Active: {rounddetail.isActive} </tspan>
                      </text>
                    </g>
                  </g>
                  <g id="countertime2" className={` ${screen === 'rounddetails' || screen === 'loser' || screen === 'winner' ? "" : "hidden"}`}>
                    <Countdown renderer={renderer} date={nextTimesunday} daysInHours={true} />

                    <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter10_d_52_392)">
                      <text className="fill-color3" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                        fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
                        <tspan x="222.939" y="673.472" id="ticketchance">X{ chances }</tspan>
                      </text>
                    </g>

                    <rect xmlns="http://www.w3.org/2000/svg" x="204.77" y="700.815" width="146.77"
                      height="101.815" transform="rotate(-180 204.77 700.815)"
                      fill="url(#pattern1_52_392)" />

                  </g>
                  <rect opacity="0.85" x="403" y="453" width="240" height="239"
                    fill="url(#pattern1_9_3795)" />
                  <Countdown renderer={renderer1} date={nextTimesunday}  />
                  <path fillRule="evenodd" clipRule="evenodd"
                    d="M36 46C16.1178 46 0 62.1177 0 82V713C0 732.882 16.1177 749 36 749H1007C1026.88 749 1043 732.882 1043 713V82C1043 62.1178 1026.88 46 1007 46H36ZM53 63C33.1177 63 17 79.1177 17 99V692C17 711.882 33.1178 728 53 728H993C1012.88 728 1029 711.882 1029 692V99C1029 79.1177 1012.88 63 993 63H53Z"
                    className="fill-color9" />
                  <rect x="10.0896" y="49.1401" width="6.72624" height="9.4203" className="fill-color2" />
                  <rect x="6.72678" y="52.2803" width="6.72624" height="9.4203" className="fill-color2" />
                  <rect x="3.36343" y="55.4204" width="3.36312" height="9.4203" className="fill-color2" />
                  <rect x="9.15527e-05" y="61.7007" width="10.0894" height="3.1401" className="fill-color2" />
                  <rect width="6.72624" height="9.4203" transform="matrix(-1 0 0 1 1035.84 49.1401)"
                    className="fill-color2" />
                  <rect width="6.72624" height="9.4203" transform="matrix(-1 0 0 1 1039.2 52.2803)"
                    className="fill-color2" />
                  <rect width="6.72624" height="9.4203" transform="matrix(-1 0 0 1 1042.57 55.4204)"
                    className="fill-color2" />
                  <rect width="6.72624" height="3.1401" transform="matrix(-1 0 0 1 1045.93 61.7007)"
                    className="fill-color2" />
                  <rect x="10.0896" y="736.822" width="6.72624" height="9.4203" className="fill-color2" />
                  <rect x="6.72678" y="733.682" width="6.72624" height="9.4203" className="fill-color2" />
                  <rect width="3.36312" height="9.4203" transform="matrix(1 0 0 -1 3.36343 739.962)"
                    className="fill-color2" />
                  <rect width="10.0894" height="3.1401" transform="matrix(1 0 0 -1 9.15527e-05 733.682)"
                    className="fill-color2" />
                  <rect width="6.72624" height="9.4203" transform="matrix(-1 0 0 1 1035.84 736.822)"
                    className="fill-color2" />
                  <rect width="6.72624" height="9.4203" transform="matrix(-1 0 0 1 1039.2 733.682)"
                    className="fill-color2" />
                  <rect x="1042.57" y="739.962" width="3.36312" height="9.4203"
                    transform="rotate(180 1042.57 739.962)" className="fill-color2" />
                  <rect x="1045.93" y="733.682" width="10.0894" height="3.1401"
                    transform="rotate(180 1045.93 733.682)" className="fill-color2" />
                  <rect x="16" y="46" width="1014" height="10" className="fill-color2" />
                  <rect x="16.8152" y="739.962" width="1012.3" height="9.4203" className="fill-color2" />
                  <rect y="64.8406" width="10.0894" height="665.701" className="fill-color2" />
                  <rect width="10.0894" height="665.701" transform="matrix(-1 0 0 1 1045.93 64.8406)"
                    className="fill-color2" />
                  <rect x="26.7632" y="65.9705" width="6.50849" height="8.91138" className="fill-color2" />
                  <rect x="23.5089" y="68.9409" width="6.50849" height="8.91138" className="fill-color2" />
                  <rect x="20.2546" y="71.9114" width="3.25425" height="8.91138" className="fill-color2" />
                  <rect x="17.0001" y="77.8523" width="9.76274" height="2.97046" className="fill-color2" />
                  <rect width="6.50849" height="8.91138" transform="matrix(-1 0 0 1 1019.31 65.9705)"
                    className="fill-color2" />
                  <rect width="6.50849" height="8.91138" transform="matrix(-1 0 0 1 1022.56 68.9409)"
                    className="fill-color2" />
                  <rect width="6.50849" height="8.91138" transform="matrix(-1 0 0 1 1025.82 71.9114)"
                    className="fill-color2" />
                  <rect width="6.50849" height="2.97046" transform="matrix(-1 0 0 1 1029.07 77.8523)"
                    className="fill-color2" />
                  <rect x="26.7632" y="716.501" width="6.50849" height="8.91138" className="fill-color2" />
                  <rect x="23.5089" y="713.531" width="6.50849" height="8.91138" className="fill-color2" />
                  <rect width="3.25425" height="8.91138" transform="matrix(1 0 0 -1 20.2546 719.471)"
                    className="fill-color2" />
                  <rect width="9.76274" height="2.97046" transform="matrix(1 0 0 -1 17.0001 713.531)"
                    className="fill-color2" />
                  <rect width="6.50849" height="8.91138" transform="matrix(-1 0 0 1 1019.31 716.501)"
                    className="fill-color2" />
                  <rect width="6.50849" height="8.91138" transform="matrix(-1 0 0 1 1022.56 713.531)"
                    className="fill-color2" />
                  <rect x="1025.82" y="719.471" width="3.25425" height="8.91138"
                    transform="rotate(180 1025.82 719.471)" className="fill-color2" />
                  <rect x="1029.07" y="713.531" width="9.76274" height="2.97046"
                    transform="rotate(180 1029.07 713.531)" className="fill-color2" />
                  <rect x="33.2707" y="63" width="979.528" height="8.91138" className="fill-color2" />
                  <rect x="33.2707" y="719.471" width="979.528" height="8.91138" className="fill-color2" />
                  <rect x="17" y="80.8228" width="9.76274" height="629.737" className="fill-color2" />
                  <rect width="9.76274" height="629.737" transform="matrix(-1 0 0 1 1029.07 80.8228)"
                    className="fill-color2" />
                  <path
                    d="M782.435 0H263.565C252.207 0 243 5.8203 243 13V106C243 113.18 252.207 119 263.565 119H782.435C793.793 119 803 113.18 803 106V13C803 5.8203 793.793 0 782.435 0Z"
                    className="fill-color9" />
                  <mask id="mask0_9_3795" style={{ "maskType":"alpha" }} maskUnits="userSpaceOnUse" x="243" y="0"
                    width="560" height="120">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M255.655 1C248.666 1 243 4.58172 243 9V111.43C243 115.848 248.666 119.43 255.655 119.43H788.703C795.692 119.43 801.358 115.848 801.358 111.43V9C801.358 4.58172 795.692 1 788.703 1H255.655ZM264.756 3.86395C257.767 3.86395 252.101 7.44567 252.101 11.8639V107.892C252.101 112.31 257.767 115.892 264.756 115.892H781.207C788.198 115.892 793.863 112.31 793.863 107.892V11.864C793.863 7.44568 788.198 3.86395 781.207 3.86395H264.756Z"
                      className="fill-color2" />
                    <path d="M252.001 1.52905H248.401V3.11602H252.001V1.52905Z" className="fill-color2" />
                    <path d="M250.201 2.05798H246.601V3.64495H250.201V2.05798Z" className="fill-color2" />
                    <path d="M246.6 2.58691H244.8V4.17388H246.6V2.58691Z" className="fill-color2" />
                    <path d="M248.401 3.6449H243V4.17389H248.401V3.6449Z" className="fill-color2" />
                    <path d="M793.925 1.52905H797.525V3.11602H793.925V1.52905Z" className="fill-color2" />
                    <path d="M795.725 2.05798H799.325V3.64495H795.725V2.05798Z" className="fill-color2" />
                    <path d="M797.526 2.58691H801.126V4.17388H797.526V2.58691Z" className="fill-color2" />
                    <path d="M799.327 3.6449H802.927V4.17389H799.327V3.6449Z" className="fill-color2" />
                    <path d="M252.001 117.378H248.401V118.965H252.001V117.378Z" className="fill-color2" />
                    <path d="M250.201 116.849H246.601V118.436H250.201V116.849Z" className="fill-color2" />
                    <path d="M246.6 117.907H244.8V116.32H246.6V117.907Z" className="fill-color2" />
                    <path d="M248.401 116.849H243V116.32H248.401V116.849Z" className="fill-color2" />
                    <path d="M793.925 117.378H797.525V118.965H793.925V117.378Z" className="fill-color2" />
                    <path d="M795.725 116.849H799.325V118.436H795.725V116.849Z" className="fill-color2" />
                    <path d="M799.326 117.907H801.126V116.32H799.326V117.907Z" className="fill-color2" />
                    <path d="M797.527 116.849H802.927V116.32H797.527V116.849Z" className="fill-color2" />
                    <path d="M793.925 0.999878H252.002V2.58685H793.925V0.999878Z" className="fill-color2" />
                    <path d="M793.925 117.907H252.002V119.494H793.925V117.907Z" className="fill-color2" />
                    <path d="M248.401 4.17383H243V116.32H248.401V4.17383Z" className="fill-color2" />
                    <path d="M797.527 4.17383H802.927V116.32H797.527V4.17383Z" className="fill-color2" />
                    <path d="M260.811 4.36401H257.327V5.86525H260.811V4.36401Z" className="fill-color2" />
                    <path d="M259.07 4.8645H255.586V6.36574H259.07V4.8645Z" className="fill-color2" />
                    <path d="M255.585 5.36475H253.843V6.86599H255.585V5.36475Z" className="fill-color2" />
                    <path d="M257.326 6.36572H252.101V6.86614H257.326V6.36572Z" className="fill-color2" />
                    <path d="M785.191 4.36401H788.675V5.86525H785.191V4.36401Z" className="fill-color2" />
                    <path d="M786.932 4.8645H790.416V6.36574H786.932V4.8645Z" className="fill-color2" />
                    <path d="M788.676 5.36475H792.16V6.86599H788.676V5.36475Z" className="fill-color2" />
                    <path d="M790.417 6.36572H793.901V6.86614H790.417V6.36572Z" className="fill-color2" />
                    <path d="M260.811 113.955H257.327V115.456H260.811V113.955Z" className="fill-color2" />
                    <path d="M259.07 113.454H255.586V114.955H259.07V113.454Z" className="fill-color2" />
                    <path d="M255.585 114.455H253.843V112.954H255.585V114.455Z" className="fill-color2" />
                    <path d="M257.326 113.454H252.101V112.953H257.326V113.454Z" className="fill-color2" />
                    <path d="M785.191 113.955H788.675V115.456H785.191V113.955Z" className="fill-color2" />
                    <path d="M786.932 113.454H790.416V114.955H786.932V113.454Z" className="fill-color2" />
                    <path d="M790.418 114.455H792.16V112.954H790.418V114.455Z" className="fill-color2" />
                    <path d="M788.675 113.454H793.901V112.953H788.675V113.454Z" className="fill-color2" />
                    <path d="M785.19 3.86389H260.812V5.36513H785.19V3.86389Z" className="fill-color2" />
                    <path d="M785.19 114.455H260.812V115.956H785.19V114.455Z" className="fill-color2" />
                    <path d="M257.326 6.86621H252.101V112.954H257.326V6.86621Z" className="fill-color2" />
                    <path d="M788.675 6.86621H793.901V112.954H788.675V6.86621Z" className="fill-color2" />
                  </mask>
                  <g mask="url(#mask0_9_3795)">
                    <path d="M803 63H243V119H803V63Z" className="fill-color2" />
                  </g>
                  <mask id="mask1_9_3795" style={{ maskType:"alpha"}} maskUnits="userSpaceOnUse" x="243" y="-1"
                    width="560" height="120">
                    <path fillRule="evenodd" clipRule="evenodd"
                      d="M257.237 0C249.374 0 243 4.02944 243 9V110C243 114.971 249.374 119 257.237 119H788.763C796.626 119 803 114.971 803 110V9C803 4.02944 796.626 0 788.763 0H257.237ZM266.338 2.86395C258.475 2.86395 252.101 6.89339 252.101 11.8639V105.892C252.101 110.863 258.475 114.892 266.338 114.892H779.626C787.489 114.892 793.863 110.863 793.863 105.892V11.864C793.863 6.89339 787.489 2.86395 779.626 2.86395H266.338Z"
                      className="fill-color2" />
                    <path d="M252.001 0.529053H248.401V2.11602H252.001V0.529053Z" className="fill-color2" />
                    <path d="M250.201 1.05798H246.601V2.64495H250.201V1.05798Z" className="fill-color2" />
                    <path d="M246.6 1.58691H244.8V3.17388H246.6V1.58691Z" className="fill-color2" />
                    <path d="M248.401 2.64502H243V3.17401H248.401V2.64502Z" className="fill-color2" />
                    <path d="M793.925 0.529053H797.525V2.11602H793.925V0.529053Z" className="fill-color2" />
                    <path d="M795.725 1.05798H799.325V2.64495H795.725V1.05798Z" className="fill-color2" />
                    <path d="M797.526 1.58691H801.126V3.17388H797.526V1.58691Z" className="fill-color2" />
                    <path d="M799.327 2.64502H802.927V3.17401H799.327V2.64502Z" className="fill-color2" />
                    <path d="M252.001 116.378H248.401V117.965H252.001V116.378Z" className="fill-color2" />
                    <path d="M250.201 115.849H246.601V117.436H250.201V115.849Z" className="fill-color2" />
                    <path d="M246.6 116.907H244.8V115.32H246.6V116.907Z" className="fill-color2" />
                    <path d="M248.401 115.849H243V115.32H248.401V115.849Z" className="fill-color2" />
                    <path d="M793.925 116.378H797.525V117.965H793.925V116.378Z" className="fill-color2" />
                    <path d="M795.725 115.849H799.325V117.436H795.725V115.849Z" className="fill-color2" />
                    <path d="M799.326 116.907H801.126V115.32H799.326V116.907Z" className="fill-color2" />
                    <path d="M797.527 115.849H802.927V115.32H797.527V115.849Z" className="fill-color2" />
                    <path d="M793.925 -0.00012207H252.002V1.58685H793.925V-0.00012207Z"
                      className="fill-color2" />
                    <path d="M793.925 116.907H252.002V118.494H793.925V116.907Z" className="fill-color2" />
                    <path d="M248.401 3.17383H243V115.32H248.401V3.17383Z" className="fill-color2" />
                    <path d="M797.527 3.17383H802.927V115.32H797.527V3.17383Z" className="fill-color2" />
                    <path d="M260.811 3.36401H257.327V4.86525H260.811V3.36401Z" className="fill-color2" />
                    <path d="M259.07 3.8645H255.586V5.36574H259.07V3.8645Z" className="fill-color2" />
                    <path d="M255.585 4.36499H253.843V5.86623H255.585V4.36499Z" className="fill-color2" />
                    <path d="M257.326 5.36572H252.101V5.86614H257.326V5.36572Z" className="fill-color2" />
                    <path d="M785.191 3.36401H788.675V4.86525H785.191V3.36401Z" className="fill-color2" />
                    <path d="M786.932 3.8645H790.416V5.36574H786.932V3.8645Z" className="fill-color2" />
                    <path d="M788.676 4.36499H792.16V5.86623H788.676V4.36499Z" className="fill-color2" />
                    <path d="M790.417 5.36572H793.901V5.86614H790.417V5.36572Z" className="fill-color2" />
                    <path d="M260.811 112.955H257.327V114.456H260.811V112.955Z" className="fill-color2" />
                    <path d="M259.07 112.454H255.586V113.955H259.07V112.454Z" className="fill-color2" />
                    <path d="M255.585 113.455H253.843V111.954H255.585V113.455Z" className="fill-color2" />
                    <path d="M257.326 112.454H252.101V111.954H257.326V112.454Z" className="fill-color2" />
                    <path d="M785.191 112.955H788.675V114.456H785.191V112.955Z" className="fill-color2" />
                    <path d="M786.932 112.454H790.416V113.955H786.932V112.454Z" className="fill-color2" />
                    <path d="M790.418 113.455H792.16V111.954H790.418V113.455Z" className="fill-color2" />
                    <path d="M788.675 112.454H793.901V111.954H788.675V112.454Z" className="fill-color2" />
                    <path d="M785.19 2.86401H260.812V4.36525H785.19V2.86401Z" className="fill-color2" />
                    <path d="M785.19 113.455H260.812V114.956H785.19V113.455Z" className="fill-color2" />
                    <path d="M257.326 5.86646H252.101V111.954H257.326V5.86646Z" className="fill-color2" />
                    <path d="M788.675 5.86646H793.901V111.954H788.675V5.86646Z" className="fill-color2" />
                  </mask>
                  <g mask="url(#mask1_9_3795)">
                    <path d="M803 0H243V56H803V0Z" className="fill-color2" />
                  </g>
                  <text className="fill-color10" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                    fontFamily="Press Start 2P" fontSize="42" letterSpacing="0em">
                    <tspan x="376" y="81">lottery</tspan>
                  </text>
                </g>
                {/* changescreen on click */}
                <rect x="915" y="201" width="71.2704" height="71.2704" rx="10" onClick={() => changeScreen()} />
                <g id="history" className={ screen === "history" ? "": "hidden"  }>
                  <g xmlns="http://www.w3.org/2000/svg" filter="url(#filter11_d_52_392)">
                    <text className="fill-color3" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                      <tspan x="248" y="173">Choose a Week :</tspan>
                    </text>
                  </g>
                  {winners1.length > 0 &&
                    <image id="season3" className="" xmlns="http://www.w3.org/2000/svg" x="639" y="246"
                    width="222" height="299" rx="10" xlinkHref={ season3img}
                     onClick={() => (ShowWinners(3))}/>
                  }
                 
                  {winners2.length > 0 && 
                    <image id="season2" className="" xmlns="http://www.w3.org/2000/svg" x="393" y="246"
                    width="222" height="299" rx="10" xlinkHref={season2img}
                    onClick={() => (ShowWinners(2))} />
                  }
               
                  {winners3.length > 0 &&  
                  <image id="season1" className="" xmlns="http://www.w3.org/2000/svg" x="147" y="246"
                    width="222" height="299" rx="10" xlinkHref={season1img}
                    onClick={() => (ShowWinners(3))} />
                  }
                  {winners2.length > 0 &&  
                    <rect className="" id="season2sw" xmlns="http://www.w3.org/2000/svg" x="393" y="246"
                      width="222" height="299" rx="10" fill="url(#paint2_linear_105_2)"
                    onClick={() => (ShowWinners(2))} />
                  }
                  {winners1.length > 0 &&  
                  <rect className="" id="season3sw" xmlns="http://www.w3.org/2000/svg" x="639" y="246"
                    width="222" height="299" rx="10" fill="url(#paint2_linear_105_2)"
                    onClick={() => (ShowWinners(1))} />
                  }
                  {winners3.length > 0 && 
                    <rect className="" id="season1sw" xmlns="http://www.w3.org/2000/svg" x="147" y="246"
                      width="222" height="299" rx="10" fill="url(#paint2_linear_105_2)"
                    onClick={() => (ShowWinners(3))} />
                  }
                 
                  { loading && 
                    <g id="loading" className="animate-spin origin-center">
                      <path className="fill-color7" xmlns="http://www.w3.org/2000/svg" opacity="0.5"
                        d="M512 341C504.089 341 496.355 343.346 489.777 347.741C483.199 352.136 478.072 358.384 475.045 365.693C472.017 373.002 471.225 381.044 472.769 388.804C474.312 396.563 478.122 403.69 483.716 409.284C489.31 414.878 496.437 418.688 504.196 420.231C511.956 421.775 519.998 420.983 527.307 417.955C534.616 414.928 540.864 409.801 545.259 403.223C549.654 396.645 552 388.911 552 381C552 375.747 550.965 370.546 548.955 365.693C546.945 360.84 543.999 356.43 540.284 352.716C536.57 349.001 532.16 346.055 527.307 344.045C522.454 342.035 517.253 341 512 341ZM512 413C505.671 413 499.484 411.123 494.222 407.607C488.959 404.091 484.858 399.093 482.436 393.246C480.014 387.399 479.38 380.965 480.615 374.757C481.85 368.55 484.897 362.848 489.373 358.373C493.848 353.897 499.55 350.85 505.757 349.615C511.965 348.38 518.399 349.014 524.246 351.436C530.093 353.858 535.091 357.959 538.607 363.222C542.123 368.484 544 374.671 544 381C544 389.487 540.629 397.626 534.627 403.627C528.626 409.629 520.487 413 512 413Z" />
                      <path xmlns="http://www.w3.org/2000/svg"
                        d="M544 381H552C552 375.747 550.965 370.546 548.955 365.693C546.945 360.84 543.999 356.43 540.284 352.716C536.57 349.001 532.16 346.055 527.307 344.045C522.454 342.035 517.253 341 512 341V349C520.487 349 528.626 352.371 534.627 358.373C540.629 364.374 544 372.513 544 381Z"
                        className="fill-color7" />
                    </g>
                  }
                 
                  {/* <!-- texts --> */}
                  {winners1.length > 0 && 
                  <g className="" id="season3txt" xmlns="http://www.w3.org/2000/svg"
                    filter="url(#filter9_d_105_2)">
                    <text className="fill-color3" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                      <tspan x="706" y="396">Week
                      </tspan>
                        <tspan id="season3number" x="737.5" y="424">{ round1Id }</tspan>

                    </text>
                    </g>
                  }
                  {winners2.length > 0 && 
                  <g className="" id="season2txt" xmlns="http://www.w3.org/2000/svg"
                    filter="url(#filter8_d_105_2)">
                    <text className="fill-color3 first-letter:uppercase" xmlSpace="preserve" style={{whiteSpace:"pre"}}
                      fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                      <tspan x="460" y="396">Week
                      </tspan>
                        <tspan id="season2number" x="491.5" y="424">{round2Id}</tspan>

                    </text>
                  </g>
                  }
                  { winners3.length > 0 && 
                    <g className="" id="season1txt" xmlns="http://www.w3.org/2000/svg"
                      filter="url(#filter7_d_105_2)">
                      <text className="fill-color3" xmlSpace="preserve" style={{ whiteSpace: "pre" }}
                        fontFamily="Press Start 2P" fontSize="25" letterSpacing="0em">
                        <tspan x="214" y="396">Week
                        </tspan>
                        <tspan id="season1number" x="245.5" y="424">{round3Id}</tspan>

                      </text>
                    </g>
                  }
              

                  {/* display popup */}
                  <g filter="url(#filter3_ii_9_3795)" onClick={() => { console.log('') }}>
                    <rect x="58" y="95" width="71.2704" height="71.2704" rx="10" className="fill-color7" onClick={() => { console.log('') }} />
                    <text className="fill-color8" xmlSpace="preserve" style={{ whiteSpace: "pre" }} onClick={() => setPopup(true)}
                      fontFamily="Press Start 2P" fontSize="48" letterSpacing="0em">
                      <tspan x="69.5" y="154.5">i</tspan>
                    </text>
                  </g>
                </g>
                <defs>
                  <filter xmlns="http://www.w3.org/2000/svg" id="filter7_d_105_2" x="182" y="377.25"
                    width="154.875" height="48.625" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.188235 0 0 0 0 0.176471 0 0 0 0 0.0705882 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_105_2" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_105_2"
                      result="shape" />
                  </filter>
                  <linearGradient xmlns="http://www.w3.org/2000/svg" id="paint2_linear_105_2" x1="750"
                    y1="246" x2="750" y2="545" gradientUnits="userSpaceOnUse">
                    <stop stopColor="var(--color-color1)" stopOpacity="0.15" />
                    <stop offset="1" stopColor="var(--color-color2)" />
                  </linearGradient>
                  <filter xmlns="http://www.w3.org/2000/svg" id="filter7_d_105_2" x="182" y="377.25"
                    width="154.875" height="48.625" filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.188235 0 0 0 0 0.176471 0 0 0 0 0.0705882 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_105_2" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_105_2"
                      result="shape" />
                  </filter>
                  <filter xmlns="http://www.w3.org/2000/svg" id="filter12_d_52_392" x="408" y="625"
                    width="578" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.180392 0 0 0 0 0.176471 0 0 0 0 0.0784314 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_52_392" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_52_392"
                      result="shape" />
                  </filter>
                  <filter xmlns="http://www.w3.org/2000/svg" id="filter10_d_52_392" x="221.939" y="625.472"
                    width="98" height="50" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.180392 0 0 0 0 0.176471 0 0 0 0 0.0784314 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_52_392" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_52_392"
                      result="shape" />
                  </filter>
                  <filter id="filter0_iif_9_3795" x="-139" y="-142" width="1934" height="1094"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="10" dy="10" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_3795" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-5" dy="-5" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_9_3795"
                      result="effect2_innerShadow_9_3795" />
                    <feGaussianBlur stdDeviation="3.5" result="effect3_foregroundBlur_9_3795" />
                  </filter>
                  <pattern id="pattern0_9_3795" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_9_3795" transform="scale(0.000520833 0.000925926)" />
                  </pattern>
                  <filter id="filter1_df_9_3795" x="-265" y="-243" width="585" height="585"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="50" operator="dilate" in="SourceAlpha"
                      result="effect1_dropShadow_9_3795" />
                    <feOffset />
                    <feGaussianBlur stdDeviation="100" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.964706 0 0 0 0 0.976471 0 0 0 0 0.784314 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9_3795" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9_3795"
                      result="shape" />
                    <feGaussianBlur stdDeviation="12.5" result="effect2_foregroundBlur_9_3795" />
                  </filter>
                  <filter id="filter2_ii_9_3795" x="912" y="93" width="75.2704" height="75.2705"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_3795" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_9_3795"
                      result="effect2_innerShadow_9_3795" />
                  </filter>
                  <filter id="filter3_ii_9_3795" x="56" y="93" width="75.2704" height="75.2705"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_3795" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_9_3795"
                      result="effect2_innerShadow_9_3795" />
                  </filter>
                  <filter id="filter4_ii_9_3795" x="913" y="199" width="75.2704" height="75.2705"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="5" dy="5" />
                    <feGaussianBlur stdDeviation="1" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_9_3795" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="-2" dy="-2" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                    <feBlend mode="normal" in2="effect1_innerShadow_9_3795"
                      result="effect2_innerShadow_9_3795" />
                  </filter>
                  <filter id="filter5_d_9_3795" x="177" y="309" width="694.25" height="98"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 1 0 0 0 0 0.458824 0 0 0 0 0.596078 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9_3795" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9_3795"
                      result="shape" />
                  </filter>
                  <pattern id="pattern1_9_3795" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image1_9_3795"
                      transform="matrix(0.000976562 0 0 0.000980649 0 -0.00160173)" />
                  </pattern>
                  <filter id="filter6_d_9_3795" x="238" y="207" width="574" height="50"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.188235 0 0 0 0 0.176471 0 0 0 0 0.0705882 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9_3795" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9_3795"
                      result="shape" />
                  </filter>
                  <filter xmlns="http://www.w3.org/2000/svg" id="filter11_d_52_392" x="247" y="148"
                    width="600" height="33" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="3" dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix type="matrix"
                      values="0 0 0 0 0.180392 0 0 0 0 0.176471 0 0 0 0 0.0784314 0 0 0 1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_52_392" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_52_392"
                      result="shape" />
                  </filter>
                  <pattern xmlns="http://www.w3.org/2000/svg" id="pattern1_52_392"
                    patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#image1_52_392"
                      transform="scale(0.00121065 0.0017452)" />
                  </pattern>
                  <image xmlns="http://www.w3.org/2000/svg" id="image1_52_392" width="826" height="573"
                    xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={ticketimg} />

                  <clipPath id="clip0_9_3795">
                    <rect width="1920" height="1080" fill="white" transform="translate(-132 -135)" />
                  </clipPath>
                </defs>
                <image id="bananalogo" xlinkHref={logo} x="450" y="500" width="200" height="200" className={` ${screen === "notconnected" ? "" : "hidden"} `} />
                <rect x="914" y="95" width="71.2704" height="71.2704" className="cursor-pointer"
                  onClick={() => changeTheme()} />

              </svg>
            </div>
          </main>

          {/* right pannel  */}



          <aside className=" md:w-4/12 w-full h-[600px] overflow-visible  md:h-auto pt-12   flex justify-end   md:pr-6 ">
            <div className=" relative  w-full md:w-fit overflow-x-hidden ">


              <svg className="w-full hidden md:flex justfiy-start    "
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 643 1140" fill="none">
                <rect className="fill-color1" x="0.583008" width="641.831" height="1140" rx="38" />
                <path fillRule="evenodd" clipRule="evenodd"
                  d="M36.583 0C16.7008 0 0.583008 16.1178 0.583008 36.0001V1103.38C0.583008 1123.26 16.7008 1139.38 36.583 1139.38H604.702C624.585 1139.38 640.702 1123.26 640.702 1103.38V36C640.702 16.1178 624.585 0 604.702 0H36.583ZM46.9184 27.991C27.0361 27.991 10.9184 44.1087 10.9184 63.991V1069.78C10.9184 1089.66 27.0361 1105.78 46.9184 1105.78H596.012C615.894 1105.78 632.012 1089.66 632.012 1069.78V63.991C632.012 44.1087 615.894 27.991 596.012 27.991H46.9184Z"
                  className="fill-color9" />
                <rect x="6.7749" y="5.08936" width="4.12753" height="15.2679" className="fill-color2" />
                <rect x="4.71094" y="10.1787" width="4.12753" height="15.2679" className="fill-color2" />
                <rect x="2.64697" y="15.2681" width="2.06377" height="15.2679" className="fill-color2" />
                <rect x="0.583008" y="25.4463" width="6.1913" height="5.08929" className="fill-color2" />
                <rect width="4.12753" height="15.2679" transform="matrix(-1 0 0 1 636.308 5.08936)"
                  className="fill-color2" />
                <rect width="4.12753" height="15.2679" transform="matrix(-1 0 0 1 638.372 10.1787)"
                  className="fill-color2" />
                <rect width="4.12753" height="15.2679" transform="matrix(-1 0 0 1 640.436 15.2681)"
                  className="fill-color2" />
                <rect width="4.12753" height="5.08929" transform="matrix(-1 0 0 1 642.5 25.4463)"
                  className="fill-color2" />
                <rect x="6.77539" y="1119.64" width="4.12753" height="15.2679" className="fill-color2" />
                <rect x="4.71094" y="1114.55" width="4.12753" height="15.2679" className="fill-color2" />
                <rect width="2.06377" height="15.2679" transform="matrix(1 0 0 -1 2.64697 1124.73)"
                  className="fill-color2" />
                <rect width="6.1913" height="5.08929" transform="matrix(1 0 0 -1 0.583008 1114.55)"
                  className="fill-color2" />
                <rect width="4.12753" height="15.2679" transform="matrix(-1 0 0 1 636.308 1119.64)"
                  className="fill-color2" />
                <rect width="4.12753" height="15.2679" transform="matrix(-1 0 0 1 638.372 1114.55)"
                  className="fill-color2" />
                <rect x="640.436" y="1124.73" width="2.06377" height="15.2679"
                  transform="rotate(180 640.436 1124.73)" className="fill-color2" />
                <rect x="642.5" y="1114.55" width="6.1913" height="5.08929"
                  transform="rotate(180 642.5 1114.55)" className="fill-color2" />
                <rect x="10.9028" width="621.277" height="15.2679" className="fill-color2" />
                <rect x="10.9033" y="1124.73" width="621.277" height="15.2679" className="fill-color2" />
                <rect x="0.583008" y="30.5356" width="6.1913" height="1078.93" className="fill-color2" />
                <rect width="6.1913" height="1078.93" transform="matrix(-1 0 0 1 642.5 30.5356)"
                  className="fill-color2" />
                <rect x="17.0083" y="32.3662" width="3.99391" height="14.443" className="fill-color1" />
                <rect x="15.0112" y="37.1812" width="3.99391" height="14.443" className="fill-color1" />
                <rect x="13.0137" y="41.9951" width="1.99696" height="14.443" className="fill-color1" />
                <rect x="11.0161" y="51.624" width="5.99087" height="4.81434" className="fill-color1" />
                <rect width="3.99391" height="14.443" transform="matrix(-1 0 0 1 626.16 32.3662)"
                  className="fill-color1" />
                <rect width="3.99391" height="14.443" transform="matrix(-1 0 0 1 628.158 37.1812)"
                  className="fill-color1" />
                <rect width="3.99391" height="14.443" transform="matrix(-1 0 0 1 630.156 41.9951)"
                  className="fill-color1" />
                <rect width="3.99391" height="4.81434" transform="matrix(-1 0 0 1 632.153 51.624)"
                  className="fill-color1" />
                <rect x="17.0088" y="1086.71" width="3.99391" height="14.443" className="fill-color1" />
                <rect x="15.0112" y="1081.89" width="3.99391" height="14.443" className="fill-color1" />
                <rect width="1.99696" height="14.443" transform="matrix(1 0 0 -1 13.0137 1091.52)"
                  className="fill-color1" />
                <rect width="5.99087" height="4.81434" transform="matrix(1 0 0 -1 11.0161 1081.89)"
                  className="fill-color1" />
                <rect width="3.99391" height="14.443" transform="matrix(-1 0 0 1 626.16 1086.71)"
                  className="fill-color1" />
                <rect width="3.99391" height="14.443" transform="matrix(-1 0 0 1 628.158 1081.89)"
                  className="fill-color1" />
                <rect x="630.156" y="1091.52" width="1.99696" height="14.443"
                  transform="rotate(180 630.156 1091.52)" className="fill-color1" />
                <rect x="632.153" y="1081.89" width="5.99087" height="4.81434"
                  transform="rotate(180 632.153 1081.89)" className="fill-color1" />
                <rect x="21.002" y="27.5522" width="601.165" height="14.443" className="fill-color1" />
                <rect x="21.0024" y="1091.52" width="601.165" height="14.443" className="fill-color1" />
                <rect x="11.0161" y="56.438" width="5.99087" height="1020.64" className="fill-color1" />
                <rect width="5.99087" height="1020.64" transform="matrix(-1 0 0 1 632.153 56.438)"
                  className="fill-color1" />
              </svg>


              <div className="absolute w-full h-full z-20 top-0   md:px-4 ">
                {/* <!-- banana bonanza text --> */}

                <svg className="w-full h-[100px] md:h-[200px] mx-auto animate-wiggle   " xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 513 183" fill="none">
                  <g filter="url(#filter0_d_217_673)">
                    <path
                      d="M32.4164 174.263L1 155.195L17.3436 128.267L21.8317 130.991L24.5556 126.503L33.5317 131.951L30.8078 136.439L35.2958 139.163L38.0198 134.675L46.9959 140.122L44.272 144.611L48.76 147.334L32.4164 174.263ZM19.912 154.391L28.0838 140.927L19.1077 135.479L10.9359 148.943L19.912 154.391ZM33.3762 162.563L41.548 149.099L32.5719 143.651L24.4001 157.115L33.3762 162.563Z"
                      className="fill-color3" />
                    <path
                      d="M56.5738 136.4L52.5693 133.005L49.1742 137.009L45.1697 133.614L48.5648 129.61L44.5604 126.215L58.1409 110.197L54.1364 106.802L40.5559 122.82L36.5515 119.424L53.5271 99.4022L57.5316 102.797L60.9267 98.7929L76.9445 112.373L56.5738 136.4ZM55.9644 129.001L66.1498 116.987L62.1453 113.592L51.96 125.605L55.9644 129.001Z"
                      className="fill-color3" />
                    <path
                      d="M79.0014 110.229L61.8943 90.3185L85.7864 69.79L89.2078 73.772L93.1898 70.3506L106.876 86.2787L98.9115 93.1215L85.2258 77.1934L73.2798 87.4577L86.9655 103.386L79.0014 110.229Z"
                      className="fill-color3" />
                    <path
                      d="M113.714 81.7291L110.961 77.2591L106.491 80.0125L103.737 75.5425L108.207 72.7891L105.454 68.3191L123.334 57.3054L120.581 52.8353L102.701 63.849L99.9471 59.379L122.297 45.6119L125.051 50.0819L129.521 47.3285L140.534 65.2086L113.714 81.7291ZM115.431 74.5057L128.841 66.2454L126.087 61.7754L112.677 70.0357L115.431 74.5057Z"
                      className="fill-color3" />
                    <path
                      d="M143.156 63.8083L133.07 39.573L162.153 27.4706L164.17 32.3176L169.017 30.3006L177.085 49.6888L167.391 53.7229L159.323 34.3347L144.781 40.3859L152.85 59.7741L143.156 63.8083Z"
                      className="fill-color3" />
                    <path
                      d="M184.997 47.4857L183.767 42.382L178.663 43.6126L177.432 38.5089L182.536 37.2782L181.306 32.1745L201.72 27.252L200.49 22.1483L180.075 27.0708L178.844 21.9671L204.363 15.8139L205.594 20.9176L210.697 19.687L215.62 40.1019L184.997 47.4857ZM188.871 41.1513L204.182 37.4595L202.951 32.3557L187.64 36.0476L188.871 41.1513Z"
                      className="fill-color3" />
                    <path
                      d="M258.154 36.6358L261.049 0L292.451 2.4818L292.038 7.71548L297.271 8.12911L296.444 18.5965L291.21 18.1828L290.797 23.4165L296.03 23.8302L295.203 34.2975L289.969 33.8839L289.556 39.1176L258.154 36.6358ZM270.276 16.5283L285.977 17.7692L286.804 7.30184L271.103 6.06094L270.276 16.5283ZM269.035 32.2293L284.736 33.4702L285.563 23.0029L269.862 21.762L269.035 32.2293Z"
                      className="fill-color3" />
                    <path
                      d="M302.836 41.1765L304.067 36.0727L298.963 34.8421L302.655 19.5309L307.759 20.7615L308.989 15.6578L334.508 21.811L333.277 26.9147L338.381 28.1453L334.689 43.4565L329.586 42.2259L328.355 47.3296L302.836 41.1765ZM309.171 37.3034L324.482 40.9952L328.174 25.6841L312.862 21.9922L309.171 37.3034Z"
                      className="fill-color3" />
                    <path
                      d="M336.3 49.4323L346.385 25.197L375.467 37.2995L373.45 42.1465L378.297 44.1636L370.229 63.5518L360.535 59.5177L368.603 40.1294L354.062 34.0782L345.994 53.4664L336.3 49.4323Z"
                      className="fill-color3" />
                    <path
                      d="M377.368 67.613L380.122 63.1429L375.652 60.3895L378.405 55.9195L382.875 58.6729L385.629 54.2029L403.509 65.2166L406.262 60.7465L388.382 49.7328L391.135 45.2628L413.486 59.0299L410.732 63.5L415.202 66.2534L404.188 84.1335L377.368 67.613ZM384.592 65.8964L398.002 74.1566L400.755 69.6866L387.345 61.4263L384.592 65.8964Z"
                      className="fill-color3" />
                    <path
                      d="M406.619 85.8448L423.726 65.9347L447.618 86.4632L444.197 90.4452L448.179 93.8667L434.493 109.795L426.529 102.952L440.215 87.0238L428.269 76.7596L414.583 92.6876L406.619 85.8448Z"
                      className="fill-color3" />
                    <path
                      d="M436.625 111.865L440.629 108.47L444.024 112.475L448.029 109.08L451.424 113.084L455.428 109.689L458.823 113.693L462.828 110.298L452.642 98.285L456.647 94.8899L480.413 122.921L476.408 126.316L473.013 122.312L469.009 125.707L465.614 121.702L461.609 125.097L458.214 121.093L454.21 124.488L464.395 136.502L460.39 139.897L436.625 111.865Z"
                      className="fill-color3" />
                    <path
                      d="M464.894 146.765L469.382 144.041L466.658 139.553L471.146 136.829L473.87 141.317L478.358 138.593L489.254 156.545L493.742 153.821L482.846 135.869L487.334 133.145L500.954 155.585L496.466 158.309L499.19 162.797L481.238 173.693L464.894 146.765ZM472.106 148.529L480.278 161.993L484.766 159.269L476.594 145.805L472.106 148.529Z"
                      className="fill-color3" />
                  </g>
                  <defs>
                    <filter xmlns="http://www.w3.org/2000/svg" id="filter0_d_217_673" x="0" y="0"
                      width="510.678" height="182.263" filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="3" dy="4" />
                      <feGaussianBlur stdDeviation="2" />

                      <feColorMatrix type="matrix"
                        values="0 0 0 0 0.188235 0 0 0 0 0.176471 0 0 0 0 0.0705882 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix"
                        result="effect1_dropShadow_217_673" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_217_673"
                        result="shape" />
                    </filter>
                  </defs>
                </svg>

                {/* <!-- cat holding money  --> */}
                <img id="bananawithmoney" draggable="false"
                  className=" object-contain -mt-14 h-fit md:block mx-auto "
                  src={
                    screen === "loser" ? catcrying : screen === "winner" ? bananadancing : bananabackground
                  } alt="banana image" width={banaSize[0]} height={banaSize[1]} />
                {/* <!-- wallet address --> */}
                <div style={{ textShadow: "3px 4px 4px var(--color-color1)" }}
                  className="w-full px-2 text-xs text-center text-color3" id="walletAddress"></div>
                {/* <!-- connect wallet btn --> */}
                <svg id="connectWallet" onClick={() => open()}
                  className={`px-6 mt-4 md:w-5/6 w-[276px] h-[76px] mx-auto shadow-inner-effect-svg ${screen !== "notconnected" ? "hidden" : ""}`}
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 272 76" fill="none">
                  <g filter="url(#filter0_ii_105_674)">
                    <rect width="272" height="76" rx="18.0952" className="fill-color5" />
                    <path
                      d="M28 44V42H26V40H24V34H26V32H28V30H36V32H38V34H34V32H30V34H28V40H30V42H34V40H38V42H36V44H28ZM42 44V42H40V36H42V34H52V36H54V42H52V44H42ZM44 42H50V36H44V42ZM56 44V34H68V36H70V44H66V36H60V44H56ZM72 44V34H84V36H86V44H82V36H76V44H72ZM90 44V42H88V36H90V34H100V36H102V40H92V42H100V44H90ZM92 38H98V36H92V38ZM106 44V42H104V36H106V34H118V36H108V42H118V44H106ZM126 44V36H122V34H126V30H130V34H134V36H130V44H126ZM154 44V42H152V30H156V38H158V30H160V38H162V30H166V42H164V44H162V42H160V40H158V42H156V44H154ZM170 44V42H168V40H170V38H178V36H170V34H180V36H182V44H170ZM172 42H178V40H172V42ZM186 44V42H190V32H188V30H194V42H198V44H186ZM202 44V42H206V32H204V30H210V42H214V44H202ZM218 44V42H216V36H218V34H228V36H230V40H220V42H228V44H218ZM220 38H226V36H220V38ZM238 44V36H234V34H238V30H242V34H246V36H242V44H238Z"
                      className="fill-color6" />
                    <rect x="5.42871" y="1.80957" width="3.61905" height="5.42857" className="fill-color6" />
                    <rect x="3.61914" y="3.61914" width="3.61905" height="5.42857" className="fill-color6" />
                    <rect x="1.80957" y="5.42859" width="1.80952" height="5.42857" className="fill-color6" />
                    <rect y="9.04761" width="5.42857" height="1.80952" className="fill-color6" />
                    <rect width="3.61905" height="5.42857" transform="matrix(-1 0 0 1 266.571 1.80957)"
                      className="fill-color6" />
                    <rect width="3.61905" height="5.42857" transform="matrix(-1 0 0 1 268.381 3.61914)"
                      className="fill-color6" />
                    <rect width="3.61905" height="5.42857" transform="matrix(-1 0 0 1 270.19 5.42859)"
                      className="fill-color6" />
                    <rect width="3.61905" height="1.80952" transform="matrix(-1 0 0 1 272 9.04761)"
                      className="fill-color6" />
                    <rect x="5.42871" y="68.7618" width="3.61905" height="5.42857" className="fill-color6" />
                    <rect x="3.61914" y="66.9524" width="3.61905" height="5.42857" className="fill-color6" />
                    <rect width="1.80952" height="5.42857" transform="matrix(1 0 0 -1 1.80957 70.5714)"
                      className="fill-color6" />
                    <rect width="5.42857" height="1.80952" transform="matrix(1 0 0 -1 0 66.9524)"
                      className="fill-color6" />
                    <rect width="3.61905" height="5.42857" transform="matrix(-1 0 0 1 266.571 68.7618)"
                      className="fill-color6" />
                    <rect width="3.61905" height="5.42857" transform="matrix(-1 0 0 1 268.381 66.9524)"
                      className="fill-color6" />
                    <rect x="270.19" y="70.5714" width="1.80952" height="5.42857"
                      transform="rotate(180 270.19 70.5714)" className="fill-color6" />
                    <rect x="272" y="66.9524" width="5.42857" height="1.80952"
                      transform="rotate(180 272 66.9524)" className="fill-color6" />
                    <rect x="9.04785" width="253.905" height="5.42857" className="fill-color6" />
                    <rect x="9.04785" y="70.5714" width="253.905" height="5.42857" className="fill-color6" />
                    <rect y="10.8572" width="5.42857" height="54.2857" className="fill-color6" />
                    <rect width="5.42857" height="54.2857" transform="matrix(-1 0 0 1 272 10.8572)"
                      className="fill-color6" />
                  </g>
                  <defs>
                    <filter id="filter0_ii_105_674" x="-2" y="-2" width="276" height="80"
                      filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                      <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="5" dy="5" />
                      <feGaussianBlur stdDeviation="1" />
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                      <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0" />
                      <feBlend mode="normal" in2="shape" result="effect1_innerShadow_105_674" />
                      <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="-2" dy="-2" />
                      <feGaussianBlur stdDeviation="1.5" />
                      <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                      <feBlend mode="normal" in2="effect1_innerShadow_105_674"
                        result="effect2_innerShadow_105_674" />
                    </filter>
                    <filter xmlns="http://www.w3.org/2000/svg" id="filter9_d_105_2" x="674" y="377.25"
                      width="154.875" height="48.625" filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="3" dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix"
                        values="0 0 0 0 0.188235 0 0 0 0 0.176471 0 0 0 0 0.0705882 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_105_2" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_105_2"
                        result="shape" />
                    </filter>
                    <filter xmlns="http://www.w3.org/2000/svg" id="filter8_d_105_2" x="428" y="377.25"
                      width="154.875" height="48.625" filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB">
                      <feFlood floodOpacity="0" result="BackgroundImageFix" />
                      <feColorMatrix in="SourceAlpha" type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                      <feOffset dx="3" dy="4" />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix type="matrix"
                        values="0 0 0 0 0.188235 0 0 0 0 0.176471 0 0 0 0 0.0705882 0 0 0 1 0" />
                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_105_2" />
                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_105_2"
                        result="shape" />
                    </filter>
                  </defs>
                </svg>
                {/* <!-- 100 1000 10000 --> */}

                <div id="buyDiv" className={`${screen === "rounddetails" || screen === "playerlist" || screen === "history" ? "" : "hidden"}`}>
                  <div className="flex text-xs gap-2 justify-center pt-4 pr-2">
                    {/* changeTicketAmonth */}
                    <div className="p-1 bg-color5 rounded-md shadow-md cursor-pointer"
                      onClick={() => setTicketAmonth(1)}>1</div>
                    <div className="p-1 bg-color5 rounded-md shadow-md cursor-pointer"
                      onClick={() => setTicketAmonth(10)}>10</div>
                    <div className="p-1 bg-color5 rounded-md shadow-md cursor-pointer"
                      onClick={() => setTicketAmonth(100)}>100</div>
                    <div className="p-1 bg-color5 rounded-md shadow-md cursor-pointer"
                      onClick={() => setTicketAmonth(1000)}>1000</div>
                    <div className="p-1 bg-color5 rounded-md shadow-md cursor-pointer"
                      onClick={() => setTicketAmonth(10000)} >10000</div>
                  </div>
                  <div className="  w-5/6  mx-auto items-center flex gap-4 md:pt-4 pt-3">
                    {/* minus btn */}
                    <img className="md:w-10 md:h-10 h-8 w-8 hover:scale-125 cursor-pointer" src={negativeimg} alt="" onClick={() => {
                      if (ticketAmonth !== 1) {
                        setTicketAmonth(ticketAmonth - 1)
                      }
                    }}
                    />
                    <input className="w-full bg-color5 rounded-md md:h-10 h-8 p-2 border-color3 border text-center text-xs"
                      type="number" id="numTickets" value={ticketAmonth} onChange={(e) => setTicketAmonth(Number(e.target.value))} />
                    {/* minus btn */}
                    <img className="md:w-10 md:h-10 h-8 w-8 hover:scale-125 cursor-pointer" src={plusimg} alt="" onClick={() => (setTicketAmonth(ticketAmonth + 1))}
                    />
                  </div>
                  <div className="flex justify-center w-full mt-2">
                    {/* buy ticket btn */}
                    <button
                      onClick={() => buyTickets(ticketAmonth)}
                      className="bg-color5 w-4/6 md:h-10 h-8 mx-auto rounded-md shadow-md md:mt-4 mt-1 hover:scale-125  text-xs md:text-sm px-1">Buy
                      Tickets</button>
                  </div>
                  <p className="chances"></p>
                </div>
                <div className="text-color4 text-sm text-center px-8">
                  <div id="winingmsg" className={`${screen === 'winner' ? "" : "hidden"}`}>
                    <p className="text-color7"> Congratulations
                    </p>

                    <p>
                      You have WON the Lottery
                    </p>
                    <p>Buy more to earn MORE!!!</p>
                    {/* display normal */}
                    <button onClick={() => setScreen("rounddetails")}
                      className="bg-color5 mx-auto rounded-md h-10 w-5/6 shadow-md mt-4 hover:scale-125 text-color6 text-sm"
                    >buy
                      ticket</button>
                    <a className=""
                      href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075">
                      <button
                        className="bg-color5 mx-auto rounded-md shadow-md mt-4 hover:scale-125 text-color6 text-sm">Look
                        Dengens Are Coming To Your Wallet !!</button>
                    </a>
                  </div>
                  <div className={`${screen === 'loser' ? "" : "hidden"}`}>
                    <p>
                      Ooh, you did't won this Week
                    </p>
                    <p>Try another chance to WIN.</p>
                    {/* display normal */}
                    <button onClick={() => setScreen("rounddetails")}
                      className="bg-color5 mx-auto rounded-md h-10 w-5/6 shadow-md mt-4 hover:scale-125 text-color6 text-sm"
                    >buy ticket</button>
                    <a className=""
                      href="https://explorer.degen.tips/address/0x4D9e662B98500F1c9Cb6B07f90999FCfD39A3075">
                      <button
                        className="bg-color5 mx-auto rounded-md shadow-md mt-4 hover:scale-125 text-color6 text-sm">Look
                        Who Won This Round !</button>
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </aside>


        </div>
      </div>
      
    </div>
  )
}